.loader {
    border: 16px solid black;
    border-top: 16px solid green;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-container {
    margin-bottom: 40px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
