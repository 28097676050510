.matching-map {
    width: 65%;
    max-width: 65%;
}

.selection-pane {
    flex: 1;
}

@media (max-width: 1200px) {
    .matching-map {
        width: 60%;
        max-width: 60%;
    }
}

@media (max-width: 992px) {
    .matching-map {
        width: 50%;
        max-width: 50%;
    }
}

@media (max-width: 768px) {
    .slider-bar {
        width: 0;
        height: 0;
        display: none !important;
    }

    .matching-map {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .selection-pane {
        flex: none;
        width: 100%;
    }
}
