$theme-colors: (
    'primary': green,
);

html {
    overflow-x: hidden;
}

*:focus {
    border-color: black !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

textarea {
    resize: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.react-bootstrap-table table {
    table-layout: auto !important;
}

select.filter {
    width: auto !important;
    max-width: 200px !important;
}

label.filter-label {
    display: block;
}

p.notes-cell {
    width: 300px !important;
    max-height: 150px !important;
    overflow: auto;
}

div.filter.date-filter {
    display: flex;
}

select.date-filter-comparator.form-control {
    width: auto;
}

input.filter.date-filter-input.form-control {
    max-width: 170px;
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table-pagination-list {
    max-width: 100%;
}

input[name='secondary-site'] {
    position: absolute;
    top: 50%;
}

label.form-check-label {
    width: 100%;
}

@import '~bootstrap/scss/bootstrap';

.csb-wrapper {
    min-height: calc(100vh - 112px) !important;
    position: relative;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .h#{$infix}-25vh {
            height: 25vh !important;
        }
        .h#{$infix}-50vh {
            height: 50vh !important;
        }
        .h#{$infix}-100vh {
            height: 100vh !important;
        }

        .h#{$infix}-25 {
            height: 25% !important;
        }

        .h#{$infix}-50 {
            height: 50% !important;
        }

        .h#{$infix}-100 {
            height: 100% !important;
        }
    }
}

.popover {
    max-width: 100vw !important;
}

.d-flex.hide {
    display: none !important;
}

.arrow {
    width: 1em;
    height: 1em;
    display: inline;
}

.arrow::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 0.5em solid transparent;
    border-left-color: gray;
    transform-origin: 0 50%;
    transition: transform 0.25s;
}

.arrow.down::before {
    transform: rotate(90deg);
    transition: transform 0.25s;
}
